import type { MedusaProduct } from "src/types";
import { BITCOIN_SKU, USA_SKU, activeProduct, updatePrice } from "./model";
import { useComputed } from "@preact/signals";
import { useSignalEffect } from "@preact/signals";
import type { GetImageResult } from "astro";
import { limitedProductsSkus } from "src/data/tangem-wallets";
import classNames from "classnames";

interface Props {
  product: MedusaProduct;
  images: Record<string, { images: GetImageResult[]; preview: GetImageResult }>;
  limitedBadgeText: string;
}

export const CardDesign = ({ product, images, limitedBadgeText }: Props) => {
  const handleCardChanged = () => {
    activeProduct.value = product;
  };

  const checked = useComputed(() => activeProduct.value?.id === product.id);

  useSignalEffect(updatePrice);

  return (
    <>
      <input
        class="selector__card-input"
        type="radio"
        name="card-design"
        value={product.id}
        aria-label={`${product.title} card design`}
        onClick={handleCardChanged}
        id={product.id}
        checked={checked}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label for={product.id} class="selector__card-label">
        {limitedProductsSkus.includes(product.variants[0].sku) ? (
          <div class="selector__badge">
            <div
              class={classNames("selector__badge-text", {
                "selector__badge-text_bitcoin":
                  product.variants[0].sku === BITCOIN_SKU,
                "selector__badge-text_usa": product.variants[0].sku === USA_SKU,
              })}
            >
              {limitedBadgeText}
            </div>
          </div>
        ) : null}
        <img
          src={images?.[product.id]?.preview.src}
          height={44}
          width={78}
          alt={`${product.title} card design`}
        />
      </label>
    </>
  );
};
